import React from 'react';

import {Resource, Resourcei} from './constant';

import './index.less';

const My: React.FC =() => {
    return <div className='my'>
        <div className='my-1'>
            <div className='my-1-content'>
                <div className='my-1-content-item'>餐饮零售业排班智能化管理平台</div>
                <div className='my-1-content-item'>智能驱动经营，精益提升效能</div>
            </div>
        </div>
        {
            Resource.map(x =>  <div
                className='my-2'
                key={x.h1}
                style={{background: x.left?'#F6F9FF': '#fff' }}
            >
            <div className='my-2-content'>
                <div className='my-2-content-item' style={
                    {
                        width: '55%',
                        ...x.left?{left: 0}: {right: 0}
                    }
                    }>
                    <div className='product-6-content-item-h1'></div>
                    <div className='product-6-content-item-h2'>{x.h1}</div>

                   <div className='my-2-content-item-h2'>{x.h2}</div>
                </div>
                <div className='my-2-content-item' style={{
                    width: '45%',
                    ...x.left?{right: 0}: {left: 0}
                    }}>
                    <img src={x.img} style={{
                        ...x.left?{right: 0}: {}
                    }} />
                </div>
            </div>
        </div>)
        }
        <div className='my-3'>
          <div className='my-3-h1'>研发实力见证-全模块自研软著证书</div>
          <div className='my-3-content'>
            {
              Resourcei.map(x => <div key={x} className='my-3-content-item'>
                <img src={x} alt="" />
              </div>)
            }
          </div>
        </div>
    </div>
}

export default My